import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import 'rxjs/add/operator/toPromise';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

	endpoint = 'https://us-central1-alain gutharc-prod.cloudfunctions.net/httpEmail';
  
  constructor(private http:  HttpClient) { }

  ngOnInit() {
  }

  sendEmail() {
    
    console.log('Sending Mail ...');

    const data = {
      toEmail: 'cbanzet@gmail.com',
      toName: 'Jeff Delaney'
    }
    this.http.post(this.endpoint, data).subscribe()
  }

}
