// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: 'AIzaSyB7rglopFse7NVOsqZ1pE0nhhEpBBZs3h4',
    authDomain: 'adc-groupe-dev.firebaseapp.com',
    databaseURL: 'https://adc-groupe-dev.firebaseio.com',
    projectId: 'adc-groupe-dev',
    storageBucket: 'adc-groupe-dev.appspot.com',
    messagingSenderId: '439162020158',
    appId: '1:439162020158:web:668f3163f1cecbd3b3cc6f',
    measurementId: 'G-XZDP843TWH'
  }
};
