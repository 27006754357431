import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logiciel',
  templateUrl: './logiciel.component.html',
  styleUrls: ['./logiciel.component.css']
})
export class LogicielComponent implements OnInit {

  showSIP2 = false
  showsmartSIP2 = false

  constructor() { }

  ngOnInit(): void {
  }

}
