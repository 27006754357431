import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';

@Injectable()
export class PublicService {

  topHomeNewsCollection   : AngularFirestoreCollection<any>;
  downHomeNewsCollection  : AngularFirestoreCollection<any>;
  pageCollection          : AngularFirestoreCollection<any>;

  candidatureOk: boolean;

  constructor(
    private router: Router,
    private afs: AngularFirestore)
  {

  }


  registerApplication(data: any) {
    this.candidatureOk = false;
    const batch = this.afs.firestore.batch();
    const id = this.afs.createId();
    const dataWithId = Object.assign(data, {$key: id, timestamp: new Date().getTime()});
    const ref = this.afs.firestore.collection('candidature').doc(id);
    batch.set(ref, dataWithId);
    batch.commit().then(() => {
      console.log( 'candidature ok' );
      this.candidatureOk = true;
    }).catch(error => {
      console.log('Erreur dans candidature', error);
    })
  }

////////////////////////////////////////////////////////////////////////////////
////////////////////////// G E T ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////



/////////////////////////////////////////////////////////////////////////////////
////////////////////////// U P D A T E //////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////



}

