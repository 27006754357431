import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit, OnDestroy {

  fileIsOK = true;
  fileIsUploading = false;
  fileUploaded = false;
  erreursMessages: string[];


  @Output() fileUploadOk = new EventEmitter<boolean>();
  //@Input() width: number;
  //@Input() heigth: number;
  @Input() size: number;
  @Input() nameUser: string;
  @Input() genre: string;
  @Input() fUrl: string;

  constructor(public sharedService: UiService) { }

  ngOnInit() {

  }

  onUploadFile(file: File) {
    this.fileIsUploading = true;
    this.sharedService.uploadFile(file, this.nameUser, this.genre).then(
      (url: string) => {
         this.sharedService.isImageUploadShown = false;
         this.fileIsUploading = false;
         this.fileUploaded = true;
         this.fileUploadOk.emit(true);
      }
    );
  }

  detectFile(event) {
      const file: File = event.target.files[0];
      if (file.type === 'application/pdf') {
        this.traitementImage(file);
      } else {
        alert('Le fichier doit être de type PDF');
      }
  }


    handleDrop(e) {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (file.type === 'application/pdf') {
        this.traitementImage(file);
      } else {
        alert('Le fichier doit être de type PDF');
      }
    }

    traitementImage(file: File) {
      this.erreursMessages = [];

      if (file.size < this.size) {
          this.onUploadFile(file);
          this.fileIsOK = true;
          
      } else {
          this.fileIsOK = false;
          this.erreursMessages.push('Le poids du fichier pdf doit etre inférieur à ' + this.size + 'octets');
      }
    }



    onDeleteDrapImage() {
      if ( this.genre === 'CV') {
        this.sharedService.deleteFile(this.sharedService.fileUrl);
        this.sharedService.fileUrl = null;
      } else {
        this.sharedService.deleteFile(this.sharedService.fileUrl2);
        this.sharedService.fileUrl2 = null;
      }

      this.sharedService.isImageUploadShown = true;

      this.fileUploaded = false;
    }

    change(e) {
      e.preventDefault();
      e.target.style.border = '5px dotted black';
    }

    resetChange(e) {
      e.preventDefault();
      e.target.style.border = '5px dotted #ccc';
    }

    ngOnDestroy(): void {
      this.sharedService.progressValue = 0;
      /*this.sharedService.currentExercice = null;
      this.sharedService.currentUser = null;
      this.sharedService.currentAliment = null;
      this.sharedService.currentTrophee = null;*/
    }
}
