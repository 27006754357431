import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
// import { User } from '../users/user';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  fileUrl: string = null;
  fileUrl2: string = null;

  progressValue = 0;
  isUploadingVideo = false;
  isVideoUploadShown = false;
  isImageUploadShown = false;

  constructor(private store: AngularFireStorage) { }



    uploadFile(file: File,  nameUser: string, genre: string, typeFile?: string) {
      return new Promise<any>((resolve, reject) => {
        let  upload: any;
        const timstamp = new Date().getTime();
        if (genre === 'CV') {
          upload = this.store.storage.ref().child(`candidature/${timstamp}_${nameUser}_cv.pdf`).put(file);
          upload.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progressValue = progress;
            // console.log('Upload is ' + progress + '% done');
          }, (error) => {
            console.log('erreur de chargement... ', error);
            reject();
          }, () => {
            upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.fileUrl = downloadURL;
                resolve(downloadURL);
            });
          });

        } else {
          upload = this.store.storage.ref().child(`candidature/${timstamp}_${nameUser}_lettre.pdf`).put(file);
          upload.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progressValue = progress;
            // console.log('Upload is ' + progress + '% done');
          }, (error) => {
            console.log('erreur de chargement... ', error);
            reject();
          }, () => {
            upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.fileUrl2 = downloadURL;
                resolve(downloadURL);
            });
          });
        }




      });
    }

    deleteFile(url: string, typeFile?: string) {
      let storageRef: any = '';
      try {
         storageRef =  this.store.storage.refFromURL(url);
      } catch (error) {

      }
      if (storageRef !== '') {
        storageRef.delete().then(
          () => {
            console.log('fichier supprimé');
          }
        ).catch(
          (error) => {
            console.log('Erreur de la suppression ' + error);
          }
        );
      }


      if (typeFile) {
        // if (this.currentExercice) {
          // this.exercicesService.newUpdateVersion(this.currentExercice, 'video', null);
        // }
      // } else {
          // if (this.currentUser) {
            // this.usersService.newUpdateVersion(this.currentUser, 'photo', null);
          // } else if (this.currentExercice) {
          //   this.exercicesService.newUpdateVersion(this.currentExercice, 'photo', null);
          // } else if (this.currentAliment) {
          //   this.nutritionService.newUpdateVersion(this.currentAliment, 'image', null);
          // } else if (this.currentTrophee) {
          //   this.tropheesService.newUpdateVersion(this.currentTrophee, 'image', null);
          // }
      }

    }




}
