import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  candidatures: any[];
  newsletters: any[];
  userstocallback: any[];
  audits: any[];
  messages: any[];

  constructor(private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.notificationService.getAllCandidature();
    this.notificationService.getAllMessageForAudit();
    this.notificationService.getAllNewsletter();
    this.notificationService.getAllUserToCallback();
    this.notificationService.getAllMessages();
    this.notificationService.notificationsSubject.subscribe( data => {
      this.candidatures = data;
    });
    this.notificationService.notifAuditSubject.subscribe( data => {
      this.audits = data;
    });
    this.notificationService.notifNewsletterSubject.subscribe( data => {
      this.newsletters = data;
    });
    this.notificationService.notifToCallbackSubject.subscribe( data => {
      this.userstocallback = data;
    });
    this.notificationService.notifMessagesSubject.subscribe( data => {
      this.messages = data;
    });
    
  }

}
