import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(/*private http: HttpClient,*/ private db: AngularFirestore) { }

  /*sendToken(token) {
    return this.http.post<any>('/token_validate', {recaptcha: token});
  }*/

  sendToken(token) {
    const batch = this.db.firestore.batch();
    const id = this.db.createId();
    const ref = this.db.firestore.collection('recaptchaVerification').doc(id);
    batch.set(ref, {tokenRecaptcha: token});
    batch.commit().then(() => console.log("success"))
                  .catch(err => console.log("Token error : ", err));
  }
}
