import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private notifCandidature: any[];
  private notifAudit: any[];
  private notifNewsletter: any[];
  private notifToCallback: any[];
  private notifMessages: any[];


  notificationsSubject = new Subject<any[]>();
  notifAuditSubject = new Subject<any[]>();
  notifNewsletterSubject = new Subject<any[]>();
  notifToCallbackSubject = new Subject<any[]>();
  notifMessagesSubject = new Subject<any[]>();

  constructor(private db: AngularFirestore) { }

  emitNotifications() {
    this.notificationsSubject.next( this.notifCandidature );
  }
  emitNotificationsAudit() {
    this.notifAuditSubject.next( this.notifAudit );
  }
  emitNotificationsNewsletter() {
    this.notifNewsletterSubject.next( this.notifNewsletter );
  }
  emitNotificationsToCallback() {
    this.notifToCallbackSubject.next( this.notifToCallback );
  }
  emitNotificationsMessages() {
    this.notifMessagesSubject.next( this.notifMessages );
  }

  getAllCandidature() {
    this.db.collection('candidature').snapshotChanges().subscribe( data => {
      this.notifCandidature = data.map(e => {
        return e.payload.doc.data()
      });
      this.emitNotifications();
    });
  }

  getAllMessageForAudit() {
    this.db.collection('messagesaudit').snapshotChanges().subscribe( data => {
      this.notifAudit = data.map(e => {
        return e.payload.doc.data()
      });
      this.emitNotificationsAudit();
    });
  }

  getAllNewsletter() {
    this.db.collection('newsletters').snapshotChanges().subscribe( data => {
      this.notifNewsletter = data.map(e => {
        return e.payload.doc.data()
      });
      this.emitNotificationsNewsletter();
    });
  }

  getAllUserToCallback() {
    this.db.collection('userstocallback').snapshotChanges().subscribe( data => {
      this.notifToCallback = data.map(e => {
        return e.payload.doc.data()
      });
      this.emitNotificationsToCallback();
    });
  }

  getAllMessages() {
    this.db.collection('messages').snapshotChanges().subscribe( data => {
      this.notifMessages = data.map(e => {
        return e.payload.doc.data()
      });
      this.emitNotificationsMessages();
    });
  }
}
