import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from '../ui/angularmaterial.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AnimateModule } from '@wizdm/animate';

import 'hammerjs';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import {CarouselModule} from 'ngx-carousel-lib';


// DEV MODULES
import { ScrollableDirective } from './scrollable.directive';
import { PublicService } from './public.service';
import { HomepageComponent, DialogAtelierComponent, DialogSendApplication } from './homepage/homepage.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { MetiersAteliersComponent } from './metiers-ateliers/metiers-ateliers.component';
import { MetiersFlottesComponent } from './metiers-flottes/metiers-flottes.component';
import { UploadComponent } from '../ui/upload/upload.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AuthComponent } from '../auth/auth.component';
import { LogicielComponent } from './logiciel/logiciel.component';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@NgModule({
  imports: [
    CarouselModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
      CommonModule,
    FlexLayoutModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ScrollToModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    ScrollingModule,
    AnimateModule
  ],
  declarations: [
    AuthComponent,
    HomepageComponent,
    ScrollableDirective,
    SafePipe,
    DialogAtelierComponent,
    MetiersAteliersComponent,
    MetiersFlottesComponent,
    DialogSendApplication,
    UploadComponent,
    NotificationsComponent,
    LogicielComponent
  ],
  exports: [
  ],
  providers: [
    PublicService,
  ],
  entryComponents: [
    DialogAtelierComponent,
    DialogSendApplication,
  ]
})
export class PublicModule { }
