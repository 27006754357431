export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: 'AIzaSyAeX4-W5LVk4PESsrBsMwd5ImI4k1rZkqw',
    authDomain: 'adc-groupe.firebaseapp.com',
    databaseURL: 'https://adc-groupe.firebaseio.com',
    projectId: 'adc-groupe',
    storageBucket: 'adc-groupe.appspot.com',
    messagingSenderId: '742860757247',
    appId: '1:742860757247:web:e78d13a79e9d735446d4ca',
    measurementId: 'G-NWEB3EVZR3'
  }
};
