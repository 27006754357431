import { Component, OnInit } from '@angular/core';
import { timer, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-metiers-ateliers',
  templateUrl: './metiers-ateliers.component.html',
  styleUrls: ['./metiers-ateliers.component.css']
})
export class MetiersAteliersComponent implements OnInit {

  slides = [
    {
      index: 1,
      imgwidth: 45,
      but: 'En savoir plus',
      title: 'Un <strong>service global</strong><br> pour la réparation et l\'entretien<br> de véhicules <strong>toutes marques</strong>',
      img : 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-global.jpg?alt=media&token=c7f7df34-c1eb-4b58-92e0-f95975c4f08e'
    },
    {
      index: 2,
      imgwidth: 40,
      but: 'Contactez le service',
      title: 'Atelier après vente<br><strong>Service Mécanique</strong><br>Entretien toutes marques',
      img : 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-mecanique.jpg?alt=media&token=896901f6-8459-497e-8ba8-d7e62157a12f'
    },
    {
      index: 3,
      imgwidth: 50,
      but: 'Contactez le service',
      title: 'Atelier après vente<br><strong>Service Carrosserie</strong><br>Entretien toutes marques',
      partnerimg: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/logo-partners-AD.png?alt=media&token=b1fdf6e3-d854-4f56-b707-592be8b71cce',
      img: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/bg-home-6.jpg?alt=media&token=b46abd76-c68b-43f0-b782-689ff88454ff'
    },
    {
      index: 4,
      imgwidth: 40,
      but: 'Contactez le service',
      title: 'Atelier après vente<br><strong>Service Pneumatique</strong><br>Entretien toute marque',
      partnerimg: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/logo-partners-EUROMASTER.png?alt=media&token=ecfe0fd7-4af2-4096-97fc-1978738124dd',
      img: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-pneumatique.jpg?alt=media&token=ff71a02d-b4eb-457c-b9da-fb6fa5c870cb'
    },
    {
      index: 5,
      imgwidth: 40,
      but: 'Contactez le service',
      title: 'Atelier après vente<br><strong>Service Vitrage</strong> à venir <br>Entretien toutes marques',
      partnerimg: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/logo-partners-GLASSAUTOSERVICE.png?alt=media&token=f26a6d2a-2421-4180-b407-d49480d15d74',
      img: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-vitrage2.jpg?alt=media&token=d3ca8e4e-4ddb-479c-98e1-aa6fff139da6'
    }
  ]

  tempsSlideShow: Observable<any>;
  slide = this.slides[0];
  slideshowtimer = 5000;
  indexSlideShown = 0;
  subscriptionSlide: Subscription;
  emailSubscriber: string = '';

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => this.launchSlideShow(), 8000);
  }

  launchSlideShow() {
    this.tempsSlideShow = timer(this.slideshowtimer, this.slideshowtimer);
    this.subscriptionSlide = this.tempsSlideShow.subscribe(x => {
      this.slide = (this.indexSlideShown + 1 < this.slides.length) ? this.slides[this.indexSlideShown+1] : this.slides[0];
      this.indexSlideShown = (this.indexSlideShown + 1 < this.slides.length) ? this.indexSlideShown + 1 : 0;
    });
  }

  changeSlide(s, t) {
    if (s<=0) {
      this.slide = this.slides[0];
      this.indexSlideShown = 0;
    }
    else {
      this.slide = (s < t) ? this.slides[s] : this.slides[0];
      this.indexSlideShown = s;
    }
    this.subscriptionSlide.unsubscribe();
    this.launchSlideShow();
  }

}
