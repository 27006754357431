import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './angularmaterial.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavComponent } from './nav/nav.component';
import { ReadmePageComponent } from './readme-page/readme-page.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { FooterNavComponent, EmailDialogComponent, DialogFooter, FormComponent } from './footer-nav/footer-nav.component';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha'

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PageNotFoundComponent } from './not-found.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { UploadComponent } from './upload/upload.component';
import { DialogComponent } from './footer-nav/footer-nav.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    ScrollToModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: [
    NavComponent,
    FooterNavComponent,
    ReadmePageComponent,
    SendEmailComponent,
    EmailDialogComponent,
    DialogFooter,
    PageNotFoundComponent,
    LoadingSpinnerComponent,
    FormComponent,
    DialogComponent
  ],
  exports: [
    NavComponent,
    FooterNavComponent
  ],
  entryComponents: [
    EmailDialogComponent,
    FormComponent,
    DialogComponent,
    DialogFooter
  ],
})
export class UiModule {

}
