import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiModule } from './ui/ui.module';
import { PublicModule } from './pages/public.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AngularMaterialModule } from './ui/angularmaterial.module';


// Environment
import { environment } from '../environments/environment.prod';
//import { environment } from '../environments/environment';

import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    UiModule,
    PublicModule,
    AngularMaterialModule,
    ScrollToModule.forRoot(),
    RouterModule,
    // AnimateModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
