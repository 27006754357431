import { Component } from '@angular/core';
import { AuthService } from '../core/auth.service';
@Component({
	styleUrls: ['./not-found.scss'],
	templateUrl: './not-found.component.html',
})
export class PageNotFoundComponent {
	show = true;


	constructor(public auth: AuthService) { }

	close() {
		this.show = true;
	}
	
}