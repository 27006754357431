import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { trigger,state,style,transition,animate,keyframes,query,stagger } from '@angular/animations';
import { PublicService } from '../public.service';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { timer, Observable, Subscription, GroupedObservable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { RecaptchaService } from 'src/app/ui/footer-nav/recaptcha.service';
import { FormComponent, EmailDialogComponent } from 'src/app/ui/footer-nav/footer-nav.component';
import { MatStepper } from '@angular/material/stepper';
import { UiService } from '../../ui/ui.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  animations: [
    trigger('slidePastilles', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('200ms', [
          animate('.1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('50ms', [
          animate('.1s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(0)', offset: 0}),
            style({opacity: 0, transform: 'translateX(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class HomepageComponent implements OnInit {

  slides: any[]
  slides2: any[]
  fadeTitle = false
  showTitle = true
  subscribeSubmit = false

  partnersConstructeurImage = [];

  partnersconstructeurs = [
    {col: 20, img: './assets/partners/partners-renault.jpg'},
    {col: 20, img: './assets/partners/partners-skoda.jpg'},
    {col: 20, img: './assets/partners/partners-peugeot.jpg'},
    {col: 20, img: './assets/partners/partners-vw.jpg'},
    {col: 20, img: './assets/partners/partners-opel.jpg'},
    {col: 20, img: './assets/partners/partners-bmw.jpg'},
    {col: 20, img: './assets/partners/partners-benz.jpg'},
    {col: 20, img: './assets/partners/partners-audi.jpg'},
    {col: 20, img: './assets/partners/partners-hyundai.jpg'},
    {col: 20, img: './assets/partners/partners-dacia.jpg'},
    {col: 20, img: './assets/partners/partners-citroen.jpg'},
    {col: 20, img: './assets/partners/partners-ds.jpg'},
    {col: 20, img: './assets/partners/partners-mini.jpg'},
    {col: 20, img: './assets/partners/partners-volvo.jpg'},
    {col: 20, img: './assets/partners/partners-toyota.jpg'},
  ]
  partnersloueurs = [
    {col: 15, img: './assets/partners/locaction.jpg'},
    {col: 15, img: './assets/partners/leaseplan.jpg'},
    {col: 15, img: './assets/partners/athlon.jpg'},
    {col: 15, img: './assets/partners/aldautomotive.jpg'},
    {col: 15, img: './assets/partners/alphabet.jpg'},
    {col: 15, img: './assets/partners/keo.jpg'},
    {col: 15, img: './assets/partners/ab2.jpg'},
    {col: 15, img: './assets/partners/parcours.jpg'},
    {col: 15, img: './assets/partners/diac.jpg'},
    {col: 15, img: './assets/partners/grouppilauka.jpg'},
    {col: 15, img: './assets/partners/arval.jpg'}
  ]
  partnersassu = [
    {col: 15, img: './assets/partners/axa.jpg'},
    {col: 15, img: './assets/partners/aon.jpg'},
    {col: 15, img: './assets/partners/april.jpg'},
    {col: 15, img: './assets/partners/areas.jpg'},
    {col: 15, img: './assets/partners/groupeassu2000.jpg'},
    {col: 15, img: './assets/partners/covea.jpg'},
    {col: 15, img: './assets/partners/creditmutuel.jpg'},
    {col: 15, img: './assets/partners/serenis.jpg'},
    {col: 15, img: './assets/partners/diot.jpg'},
    {col: 15, img: './assets/partners/gras.jpg'},
    {col: 15, img: './assets/partners/macsf.jpg'},
    {col: 15, img: './assets/partners/marsh.jpg'},
    {col: 15, img: './assets/partners/maif.jpg'},
    {col: 15, img: './assets/partners/mfa.jpg'},
    {col: 15, img: './assets/partners/mutuellepoitiers.jpg'},
    {col: 15, img: './assets/partners/reparmut.jpg'},
    {col: 15, img: './assets/partners/saintgobain.jpg'},
    {col: 15, img: './assets/partners/swiss.jpg'},
    {col: 15, img: './assets/partners/theoreme.jpg'},
    {col: 15, img: './assets/partners/veolia.jpg'},
    {col: 15, img: './assets/partners/verlingue.jpg'},
    {col: 15, img: './assets/partners/verspieren.jpg'},
  ]
  partnerspneucaro = [
    {col: 33, img: './assets/partners/euromaster.jpg'},
    {col: 33, img: './assets/partners/ad.jpg'},
    {col: 33, img: './assets/partners/glass.jpg'}
  ]
  partnerssoftware = [
    {col: 100, img: './assets/partners/bergerlevrault.jpg'},
  ]
  partners = [];
  pt: string;
  bgnewsletter = './assets/bg-newsletter.jpg'
  bgrecrut = './assets/bg-recrutement.jpg'

  tempsSlideShow: Observable<any>;
  slide: any;
  slide2: any;

  slideshowtimer = 6000;
  indexSlideShown = 0;
  subscriptionSlide: Subscription;
  emailSubscriber: string = '';

  imageLoaded = false;

  imgSlideOne1 = new Image();
  imgSlideOne2 = new Image();
  imgSlideOne3 = new Image();
  imgSlideOne4 = new Image();
  imgSlideOne5 = new Image();
  imgSlideOne6 = new Image();
  imgSlideOne7 = new Image();

  imgSlideTwo1 = new Image();
  imgSlideTwo2 = new Image();
  imgSlideTwo3 = new Image();
  imgSlideTwo4 = new Image();
  imgSlideTwo5 = new Image();

  bgsoft = './assets/bgsoft1.jpg'
  bgsoft1= './assets/bgsoft1.jpg'
  bgsoft2= './assets/bgsoft-desktop.png'
  bgsoft3= './assets/bgsoft-mobile.png'
  softBgSize = 'cover'
  softBgPos = 'no-repeat center center'
  widthSoftImg=45
  softBgImg: string
  softpartShown: string;

  constructor(
    @Inject(DOCUMENT) document,
    private ms: Meta,
    private ts: Title,
    private _sanitizer: DomSanitizer,
    private db: AngularFirestore,
    private dialog: MatDialog)
  {
    this.ts.setTitle('ADC GROUPE Automobile');
    this.ms.addTag({ name: 'description', content: 'Autour des ateliers après vente de carrosserie, mécanique, pneumatique et vitrage, ADC GROUPE offre un service à 360° pour la gestion de votre parc automobile.' });
    this.ms.addTag({ name: 'author', content: 'ADC GROUPE' });
    this.ms.addTag({ name: 'keywords', content: 'ADC GROUPE, ADC Groupe Automobile, Garage, Carroserie, Mécanique, Pneumatique, Vitrage, Gestion de Flotte' });

    this.imgSlideOne1.src = './assets/bg-home-hor-1.jpg';
    this.imgSlideOne2.src = './assets/bg-home-hor-3.jpg';
    this.imgSlideOne3.src = './assets/bg-home-hor-6.jpg';
    this.imgSlideOne4.src = './assets/bg-home-hor-1.jpg';
    this.imgSlideOne5.src = './assets/bg-home-hor-5.jpg';
    // this.imgSlideOne6.src = './assets/bg-home-7.jpg';
    this.imgSlideOne6.src = './assets/bg-home-9.jpg';
    this.imgSlideOne7.src = './assets/bg-home-soft.jpg';

    this.softBgImg = this.bgsoft1;

    this.preloadImg(this.partnersconstructeurs);

    this.slides = [
      {
        index: 1,
        but: 'En savoir plus',
        title: 'Un service à 360°<br>pour la gestion <br>de votre parc automobile',
        img: this.imgSlideOne1.src,
        imgpos: 'bottom center',
        scrollto: 'culture'
      },
      {
        index: 2,
        but: 'En savoir plus',
        title: 'L’expertise métier <br>de nos équipes <br>après-vente à votre service',
        img: this.imgSlideOne2.src,
        imgpos: 'bottom center',
        scrollto: 'ateliers'
      },
      {
        index: 3,
        but: 'En savoir plus',
        title: 'A vos côtés depuis 1996 <br> pour vous accompagner dans<br>la gestion de votre flotte de véhicules',
        img: this.imgSlideOne3.src,
        imgpos: 'bottom center',
        scrollto: 'partenaires'
      },
      {
        index: 4,
        but: 'Rencontrez nous',
        title: 'Un service de proximité<br>et une couverture nationale<br>grâce à nos réseaux partenaires',
        img: this.imgSlideOne5.src,
        imgpos: 'bottom center',
        scrollto: 'partenaires'
      },
      {
        index: 5,
        but: 'Contactez nous',
        title: 'Nouvelle autorisation d\'activité<br> pour les clients basés <br>sur les zones aéroportuaires',
        img: this.imgSlideOne6.src,
        imgpos: 'bottom center',
        scrollto: 'contacts'
      },
      {
        index: 6,
        but: 'En savoir plus',
        title: 'Logiciel de gestion de flotte :<br>Gain de temps<br>et Réduction des coûts',
        img: this.imgSlideOne7.src,
        imgpos: 'bottom center',
        scrollto: 'logiciel'
      }
    ];
    this.slide = this.slides[0];


    this.imgSlideTwo1.src = 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-global.jpg?alt=media&token=c7f7df34-c1eb-4b58-92e0-f95975c4f08e';
    this.imgSlideTwo2.src = 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-mecanique.jpg?alt=media&token=896901f6-8459-497e-8ba8-d7e62157a12f';
    this.imgSlideTwo3.src = 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/bg-home-6.jpg?alt=media&token=b46abd76-c68b-43f0-b782-689ff88454ff';
    this.imgSlideTwo4.src = 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-pneumatique2.jpg?alt=media&token=8c89ecc9-68c4-4d38-b467-072aced6b420';
    this.imgSlideTwo5.src = 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/atelier-vitrage2.jpg?alt=media&token=d3ca8e4e-4ddb-479c-98e1-aa6fff139da6'
    this.slides2 = [
      {
        index: 1,
        imgwidth: 40,
        but: 'Contacter le service',
        nom: 'Service Commercial',
        email: 'contact@adc-groupe.com',
        title: 'Un <strong>service complet</strong><br> pour la réparation et l\'entretien<br> de véhicules <strong>toutes marques</strong>',
        img : this.imgSlideTwo1.src
      },
      {
        index: 2,
        imgwidth: 40,
        email: 'contact@adc-groupe.com',
        tel: '+33965109950',
        nom: 'Service Mécanique',
        but: 'Contactez le service',
        title: 'Atelier après-vente<br><strong>Service Mécanique</strong><br>Toutes marques',
        img : this.imgSlideTwo2.src
      },
      {
        index: 3,
        imgwidth: 40,
        but: 'Contactez le service',
        email: 'contact@adc-groupe.com',
        tel: '+33965109950',
        nom: 'Service Carrosserie',
        title: 'Atelier après-vente<br><strong>Service Carrosserie</strong><br>Toutes marques',
        partnerimg: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/logo-partners-AD.png?alt=media&token=b1fdf6e3-d854-4f56-b707-592be8b71cce',
        img: this.imgSlideTwo3.src
      },
      {
        index: 4,
        imgwidth: 40,
        but: 'Contactez le service',
        email: 'servicepneumatiques@adc-groupe.com',
        tel: '+33149891324',
        nom: 'Service Pneumatique',
        title: 'Atelier après-vente<br><strong>Service Pneumatique</strong><br>Toutes marques',
        partnerimg: 'https://firebasestorage.googleapis.com/v0/b/adc-groupe.appspot.com/o/logo-partners-EUROMASTER.png?alt=media&token=ecfe0fd7-4af2-4096-97fc-1978738124dd',
        img: this.imgSlideTwo4.src
      },
      {
        index: 5,
        imgwidth: 40,
        but: 'Contactez le service',
        email: 'contact@adc-groupe.com',
        tel: '+33965109950',
        nom: 'Service Vitrage',
        title: 'Atelier après-vente<br><strong>Service Vitrage</strong> toutes marques, <br>à venir',
        img: this.imgSlideTwo5.src
      }
    ];
    this.slide2 = this.slides2[0];
  }








  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {


    const elAvisT = document.querySelector('#AFF-AVIS-T');
    const posAvisT = elAvisT.getBoundingClientRect();
    if(posAvisT.top >= 0 && posAvisT.bottom <= window.innerHeight) {
      elAvisT.className = 'avis-aff-t';
    }

    const elClTiG = document.querySelector('#clienttitle-G');
    const posClTiG = elClTiG.getBoundingClientRect();
    if(posClTiG.top >= 0 && posClTiG.bottom <= window.innerHeight) {
      elClTiG.className = 'avis-aff-titleG';
    }
    const elClTiD = document.querySelector('#clienttitle-D');
    const posClTiD = elClTiG.getBoundingClientRect();
    if(posClTiD.top >= 0 && posClTiD.bottom <= window.innerHeight) {
      elClTiD.className = 'avis-aff-titleD';
    }

    const eleIMG1 = document.querySelector('#AFF-IMG-1');
    const posIMG1 = eleIMG1.getBoundingClientRect();
    if(posIMG1.top >= 0 && posIMG1.bottom <= window.innerHeight) {
      eleIMG1.className = 'round-aff-scroll';
    }

    const eleIMG2 = document.querySelector('#AFF-IMG-2');
    const posIMG2 = eleIMG2.getBoundingClientRect();
    if(posIMG2.top >= 0 && posIMG2.bottom <= window.innerHeight) {
      eleIMG2.className = 'round-aff-scroll';
    }

    const eleRDTT1 = document.querySelector('#AFF-ROUND-TXT1');
    const posRDTT1 = eleIMG1.getBoundingClientRect();
    if(posRDTT1.top >= 0 && posRDTT1.bottom <= window.innerHeight) {
      eleRDTT1.className = 'aff-rd-txt1';
    }

    const eleRDTT2 = document.querySelector('#AFF-ROUND-TXT2');
    const posRDTT2 = eleIMG2.getBoundingClientRect();
    if(posRDTT2.top >= 0 && posRDTT2.bottom <= window.innerHeight) {
      eleRDTT2.className = 'aff-rd-txt2';
    }



    // AUDIT
    const elAUDITH1 = document.querySelector('#AFF-AUDIT-H1');
    const poslAUDITH1 = elAUDITH1.getBoundingClientRect();
    if(poslAUDITH1.top >= 0 && poslAUDITH1.bottom <= window.innerHeight) {
      elAUDITH1.className = 'audith1';
    }

    const elAUDITH3 = document.querySelector('#AFF-AUDIT-H3');
    const poslAUDITH3 = elAUDITH3.getBoundingClientRect();
    if(poslAUDITH3.top >= 0 && poslAUDITH3.bottom <= window.innerHeight) {
      elAUDITH3.className = 'audith3';
    }

    const elAUDITP1 = document.querySelector('#AFF-AUDIT-P1');
    const posAUDITP1 = elAUDITP1.getBoundingClientRect();
    if(posAUDITP1.top >= 0 && posAUDITP1.bottom <= window.innerHeight) {
      elAUDITP1.className = 'audit-aff-p1';
    }

    const elAUDITP2 = document.querySelector('#AFF-AUDIT-P2');
    const posAUDITP2 = elAUDITP2.getBoundingClientRect();
    if(posAUDITP2.top >= 0 && posAUDITP2.bottom <= window.innerHeight) {
      elAUDITP2.className = 'audit-aff-p2';
    }

  }


  ngOnInit() {
    this.imageLoaded = true;
    setTimeout(() => this.launchSlideShow(), 8000);
    this.changePartners();
    this.softpartShown = 'softhome';
  }

  preloadImg(arrayImage: any[]) {
    for(let i = 0; i < arrayImage.length; i++) {
      this.partnersConstructeurImage[i] = new Image();
      this.partnersConstructeurImage[i].src = arrayImage[i].img;
    }
  }


  launchSlideShow() {
    this.tempsSlideShow = timer(this.slideshowtimer, this.slideshowtimer);
    this.subscriptionSlide = this.tempsSlideShow.subscribe(x => {
      this.slide = (this.indexSlideShown + 1 < this.slides.length) ? this.slides[this.indexSlideShown+1] : this.slides[0];
      this.indexSlideShown = (this.indexSlideShown + 1 < this.slides.length) ? this.indexSlideShown + 1 : 0;
    });
  }

  changePartners(p?) {
    switch(p) {
      case 'c':   this.partners = this.partnersconstructeurs; this.pt = 'c'; break;
      case 'l':   this.partners = this.partnersloueurs; this.pt = 'l'; break;
      case 'a':   this.partners = this.partnersassu; this.pt = 'a'; break;
      case 'p':   this.partners = this.partnerspneucaro; this.pt = 'p'; break;
      case 's':   this.partners = this.partnerssoftware; this.pt = 's'; break;
      default :   this.partners = this.partnersconstructeurs; this.pt = 'c'; break;
    }
    console.log(this.pt);
  }

  changeSlide(s, t) {
    this.showTitle = false;
    console.log(s,t);
    if (s<=0) {
      this.slide = this.slides[0];
      this.indexSlideShown = 0;
      this.showTitle = true;
    }
    else {
      this.slide = (s < t) ? this.slides[s] : this.slides[0];
      this.indexSlideShown = s;
      this.showTitle = true;
    }
    if(typeof this.subscriptionSlide !== 'undefined' ) this.subscriptionSlide.unsubscribe();
    this.launchSlideShow();
  }

  changeSlide2(s, t) {
    if (s<=0) {
      this.slide2 = this.slides2[0];
      this.indexSlideShown = 0;
    }
    else {
      this.slide2 = (s < t) ? this.slides2[s] : this.slides2[0];
      this.indexSlideShown = s;
    }
    this.subscriptionSlide.unsubscribe();
    this.launchSlideShow();
  }


  changeSoftPart(p) {
    console.log(p)
    switch(p) {
      case 'av':
        this.softBgImg = this.bgsoft1;
        this.softpartShown = 'avantages';
        this.softBgSize = 'cover'; this.softBgPos = 'no-repeat center center'
        this.widthSoftImg = 20
        break;
      case 'si':
        this.softBgImg = this.bgsoft2;
        this.softpartShown = 'sip2';
        this.softBgSize = '100%'; this.softBgPos = 'no-repeat center center'
        this.widthSoftImg = 50
        break;
      case 'ss':
        console.log(this.softpartShown, this.softBgSize, this.softBgPos);
        this.softBgImg = this.bgsoft3;
        this.softpartShown = 'smartsip2';
        this.softBgSize = '100%'; this.softBgPos = 'no-repeat center center'
        this.widthSoftImg = 45
        break;
      case 'ho':
        this.softBgImg = this.bgsoft1;
        this.softpartShown = 'softhome';
        this.softBgSize = 'cover'; this.softBgPos = 'no-repeat center center'
        this.widthSoftImg = 45
      default :
        break;
    }
  }

  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url('${image}')`);
  }

  gotoExternalHref(url) {
    window.open(url, '_blank');
  }


  changeCitation() {
    console.log('another happy client quote');
  }



  joinProgram() {
    const patt = new RegExp('^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$');
    if ( patt.test(this.emailSubscriber) ) {
      const batch = this.db.firestore.batch();
      const id = this.db.createId();
      const ref = this.db.firestore.collection('newsletters').doc(id);
      batch.set(ref, {email: this.emailSubscriber, timestamp: new Date().getTime()});
      batch.commit().then(() => {
        console.log('Souscription au programme réussi');
        this.emailSubscriber = '';
      }).catch(error => {
        console.log('Erreur souscription au programme', error);
      });

    }
  }

  openSenderMailEcrivezNousPourUnAudit(): void {
    const dialogConfig = new MatDialogConfig();
    if ( window.innerWidth <= 640 ) {
      dialogConfig.width = '80%';
    } else {
      dialogConfig.width = '50%';
    }
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {message: 'Bonjour, ...'};
    this.dialog.open(FormComponent, dialogConfig);
  }


  openDialogPriseRendezVous(): void {
    const dialogConfig = new MatDialogConfig();
    if ( window.innerWidth <= 640 ) {
      dialogConfig.width = '80%';
    } else {
      dialogConfig.width = '50%';
    }
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {message: 'Bonjour, Je souhaiterais prendre un rendez vous avec vos services.'};
    this.dialog.open(FormComponent, dialogConfig);
  }

  openSenderMailEcrivezNousRejoindre(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '350px';
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {message: 'Bonjour, Je souhaiterais rejoindre votre équipe.'};
    this.dialog.open(DialogSendApplication, dialogConfig);
  }

  openDialogAtelier(slide: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.autoFocus = true;
    dialogConfig.data = slide;
    this.dialog.open(DialogAtelierComponent, dialogConfig);
  }
}














@Component({
  selector: 'app-dialog-atelier',
  templateUrl: './dialog-atelier.html',
  styleUrls: ['./dialog-atelier.css']
})
export class DialogAtelierComponent implements OnInit {

  data: any;

  constructor(public dialogRef: MatDialogRef<DialogAtelierComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: any,) {

  }

  ngOnInit(){
    this.data = this.datas;
  }
}


















/*  ENVOYER CANDIDATURE */
@Component({
  selector: 'app-dialog-send-application',
  templateUrl: './dialog-send-application.html',
  styleUrls: ['./dialog-send-application.css']
})
export class DialogSendApplication implements OnInit {

  stepForm1: FormGroup;
  stepForm2: FormGroup;
  stepForm3: FormGroup;
  stepForm4: FormGroup;

  isSend = false;

  messageAfterSent = false;
  displayButtonSend = false;
  @ViewChild('recaptcha', {static: true}) recaptchaElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    private publicService: PublicService,
    private formBuilder: FormBuilder,
    private recaptchaService: RecaptchaService,
    public uploadService: UiService,
    @Inject(MAT_DIALOG_DATA)public data: any) { }

  ngOnInit() {
    this.initAllStepForm();
  }

  // Initialisation des formulaires du stepper
  initAllStepForm() {
    this.stepForm1 = this.formBuilder.group({
      nom: ['', Validators.required],
      prenoms: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      numero: ['', Validators.required]
    });
    this.stepForm2 = this.formBuilder.group({
      service: ['', Validators.required]
    });
    this.stepForm3 = this.formBuilder.group({
      message: [this.data.message ? this.data.message : '', Validators.required]
    });
    this.stepForm4 = this.formBuilder.group({

      recaptchaReactive: [null, Validators.required]
    });
  }


  nextStepRadio(stepper: MatStepper) {
      stepper.next();
  }

  nextStepUpload(stepper: MatStepper, event: boolean) {
    console.log(event);
    if(event == true) {
      stepper.next();
    }
  }



  sendTokenTOBackEnd(tok) {
    this.recaptchaService.sendToken(tok)
  }

  onClose() {
    this.dialogRef.close();
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.sendTokenTOBackEnd(captchaResponse);
    this.displayButtonSend = true;
  }

  sendApplication() {

    if (this.uploadService.fileUrl && this.uploadService.fileUrl2) {
      const data = Object.assign({}, this.stepForm1.value, this.stepForm2.value, this.stepForm3.value, {
        cv: this.uploadService.fileUrl,
        lettre: this.uploadService.fileUrl2
      });
      this.publicService.registerApplication(data);
      this.isSend = true;
    }

  }


}








