import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomepageComponent } from './pages/homepage/homepage.component';
import { PageNotFoundComponent } from './ui/not-found.component';

import { MetiersAteliersComponent } from './pages/metiers-ateliers/metiers-ateliers.component';
import { MetiersFlottesComponent } from './pages/metiers-flottes/metiers-flottes.component';
import { AuthComponent } from './auth/auth.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { LogicielComponent } from './pages/logiciel/logiciel.component';


const routes: Routes = [
  // { path: 'sendmail', component: SendEmailComponent, canActivate: [AdminGuard] },
  ///////////////////////////////////////////////////////////////////////////////
  { path: 'metiers-ateliers', component: MetiersAteliersComponent},
  { path: 'metiers-flottes', component: MetiersFlottesComponent},
  { path: 'logiciel', component: LogicielComponent},
  {path: 'auth', component: AuthComponent},
  {path: 'notifications', canActivate: [AuthGuardService], component: NotificationsComponent},
  ///////////////////////////////////////////////////////////////////////////////
  { path: '', component: HomepageComponent, pathMatch: 'full'},
  //////////////////////////////////////////////////////////////////////////////
  { path: '**', component: PageNotFoundComponent },
  { path: 'notfound', component: PageNotFoundComponent }
  ///////////////////////////////////////////////////////////////////////////////

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
