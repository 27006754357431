import { Component, OnInit, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { RecaptchaService } from './recaptcha.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  @Input() pathlevel: number;
  path: any;

  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit() {

    switch (this.pathlevel) {
      case 0:
        this.path =  './';
        break;
      case 1:
        this.path =  './../';
        break;
      case 2:
        this.path =  './../../';
        break;
    }

  }

  gotoExternalHref(url) {
    window.open(url, '_blank');
  }

  openDialog(part): void {
    const dialogRef = this.dialog.open(DialogFooter, {
      width: '650px',
      backdropClass: 'footerDialog',
      data: {
          part: part
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  openSenderMail(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '350px';
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.autoFocus = true;
    dialogConfig.data = { message : ''};
    this.dialog.open(EmailDialogComponent, dialogConfig);
  }

  openSenderMailEcrivezNous(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {message: ''};
    this.dialog.open(FormComponent, dialogConfig);
  }


  openDialogRappel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '450px';
    dialogConfig.backdropClass = 'modal-cb';
    dialogConfig.panelClass = 'modal-cb-in';
    dialogConfig.data = {rendezvous: false, rappel: true};
    this.dialog.open( DialogComponent, dialogConfig );
  }

}



@Component({
  selector: 'dialog-footer',
  templateUrl: './dialog-footer.html',
  styleUrls: ['./dialog-footer.scss']
})
export class DialogFooter {

  allartists: Observable<any[]>;
  genres: any;
  isgenre = false;
  seasons: any;
  isseason = false;
  isartist = false;
  part: string;
  dialogTitle: string;


  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogFooter>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.part = data.part;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  ngOnInit() {
    console.log(this.part);
    if(this.part === 'terms') {
      this.dialogTitle = 'Privacy policy ADC GROUPE WEBSITE';
    }
    else if (this.part==='cookies') {
      this.dialogTitle = 'Cookies';
    }
    else if (this.part==='jobs') {
      this.dialogTitle = 'Jobs Application';
    }
    else if (this.part==='legal') {
      this.dialogTitle = 'Legal Notice';
    }
  }



  gotoExternalHref(url) {
    window.open(url, '_blank');
  }
}





@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.css']
})
export class EmailDialogComponent implements OnInit {

  form: FormGroup;
  messageAfterSent = false;
  displayButtonSend = false;
  @ViewChild('recaptcha', {static: true}) recaptchaElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    private db: AngularFirestore,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private recaptchaService: RecaptchaService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      subject: [''],
      message: [this.data.message ? this.data.message: '', Validators.required],
      recaptchaReactive: [null, Validators.required]
    });
  }

  sendTokenTOBackEnd(tok) {
    this.recaptchaService.sendToken(tok)
  }

  onClose() {
    this.dialogRef.close();
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.sendTokenTOBackEnd(captchaResponse);
    this.displayButtonSend = true;
  }

  sendMail() {
    const batch = this.db.firestore.batch();
    const idRef = this.db.createId();
    const ref = this.db.firestore.collection('messages').doc(idRef);
    batch.set(ref, {
      emailFrom: this.form.value.email,
      subject: this.form.value.subject,
      message: this.form.value.message,
      emailTo: 'cbanzet@gmail.com',
      $key: idRef,
      timestamp: new Date().getTime()
    });
    batch.commit().then(() => {
                            this.messageAfterSent = true;
                            console.log('Envoyé avec succès');
                          }
                        );
    // this.onClose();
  }

}










@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css']
})
export class FormComponent implements OnInit {

  form: FormGroup;
  stepForm1: FormGroup;
  stepForm2: FormGroup;
  stepForm3: FormGroup;
  stepForm4: FormGroup;

  isSend = false;

  messageAfterSent = false;
  displayButtonSend = false;
  @ViewChild('recaptcha', {static: true}) recaptchaElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    private db: AngularFirestore,
    private formBuilder: FormBuilder,
    private recaptchaService: RecaptchaService,
    @Inject(MAT_DIALOG_DATA)public data: any) { }

  ngOnInit() {
    this.initAllStepForm();
  }

  // Initialisation des formulaires du stepper
  initAllStepForm() {
    this.stepForm1 = this.formBuilder.group({
      nom: ['', Validators.required],
      raisonsocial: ['', Validators.required],
      nombreVehicule: [null, Validators.required]
    });
    this.stepForm2 = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.stepForm3 = this.formBuilder.group({
      message: [this.data.message ? this.data.message : '', Validators.required]
    });
    this.stepForm4 = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      tel: ['', [Validators.required, Validators.pattern("(0|\\+33|0033)[1-9][0-9]{8}")]],
      recaptchaReactive: [null, Validators.required]
    });
  }

  nextStep(stepper: MatStepper) {
    if (this.stepForm1.value.nom) {
      stepper.next();
    }
  }

  nextStepSelect(stepper: MatStepper) {
      stepper.next();
  }



  sendTokenTOBackEnd(tok) {
    this.recaptchaService.sendToken(tok)
  }

  onClose() {
    this.dialogRef.close();
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.sendTokenTOBackEnd(captchaResponse);
    this.displayButtonSend = true;
  }

  sendMail() {
    const batch = this.db.firestore.batch();
    const idRef = this.db.createId();
    const ref = this.db.firestore.collection('messagesaudit').doc(idRef);
    batch.set(ref, {
      nom: this.stepForm1.value.nom,
      raisonsocial: this.stepForm1.value.raisonsocial,
      nombreVehicule: this.stepForm1.value.nombreVehicule,
      tel: this.stepForm4.value.tel,
      emailFrom: this.stepForm4.value.email,
      message: this.stepForm3.value.message,
      emailTo: this.stepForm2.value.contact,
      $key: idRef,
      type: 'contact',
      timestamp: new Date().getTime()
    });
    batch.commit().then(() => {
                            this.messageAfterSent = true;
                            this.isSend = true;
                            console.log('Envoyé avec succès');
                          }
      );
  }

}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.css']
})
export class DialogComponent implements OnInit {

  rendezvous: boolean;
  rappel: boolean;
  formRappel: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private db: AngularFirestore,
    private recaptchaService: RecaptchaService,
  ) {}

  ngOnInit() {
    this.rappel = this.data.rappel;
    this.rendezvous = this.data.rendezvous;
    console.log(this.rappel, this.rendezvous);

    this.formRappel = this.formBuilder.group({
      nom: ['', Validators.required],
      numero: ['', [Validators.required, Validators.pattern("(0|\\+33|0033)?[1-9]([ ][0-9]{2}){4}")]],
      recaptchaReactive: [null, Validators.required]
    })
  }


  sendMailRappel() {
    /* Enregistrement dans firestore */
    const batch = this.db.firestore.batch();
    const id = this.db.createId();

    if (this.rappel) {
      const data = Object.assign({}, this.formRappel.value, {$key: id, type: 'rappel', timestamp: new Date().getTime()});
      const ref = this.db.firestore.collection('userstocallback').doc(id);
      batch.set(ref, data);
      batch.commit().then(() => {
        console.log('Email de rappel envoyé');
        this.dialogRef.close();
      })
           .catch(err => console.log(err));
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.sendTokenTOBackEnd(captchaResponse);
  }

  sendTokenTOBackEnd(tok) {
    this.recaptchaService.sendToken(tok)
  }
}