import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

//import { firebase } from '@firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
// import { NotifyService } from './notify.service';

import { Observable, of, interval } from 'rxjs';
import { switchMap, tap, take } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'rxjs/Rx';
/* import { ComponentFactoryResolver } from '@angular/core/src/render3'; */

// const Rx = require('rxjs')



interface User {
  uid: string | null;
  email?: string | null;
  photoURL?: string;
  displayName?: string;
  role?:        string;
  inline?: boolean;
}

@Injectable()
export class AuthService {
  user: Observable<User | null>;
  userOnline : any ;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
   // private notify: NotifyService
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          if (user.uid) {
            this.userOnline = user;
             return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        } else {
          return of(null);
        }
      })
 );
  //  this.logClass.getThelastTimestampInLog(this.afs, this.afAuth).subscribe((reponse) => {
  //     console.log(reponse);
  //     },
  //     (error) => {
  //       console.error('Oops:', error.message);
  //       },
  //       () => {console.log(`We're done here!`); }
  //     );
}



  ////// OAuth Methods /////

  googleLogin() {
    const provider = new firebase.default.auth.GoogleAuthProvider();
    console.log(provider);
    return this.oAuthLogin(provider);
  }

  githubLogin() {
    const provider = new firebase.default.auth.GithubAuthProvider();
    return this.oAuthLogin(provider);
  }

  facebookLogin() {
    const provider = new firebase.default.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  twitterLogin() {
    const provider = new firebase.default.auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider)
      .then(credential => {

        return this.updateUserData(credential.user);
      })
      .catch(error => this.handleError(error));
  }

  //// Anonymous Auth ////

  anonymousLogin() {
    return this.afAuth.signInAnonymously()
      .then(credential => {
        return this.updateUserData(credential.user); // if using firestore
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  //// Email/Password Auth ////

  emailSignUp(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(credential => {
        return this.updateUserData(credential.user); // if using firestore
      })
      .catch(error => this.handleError(error));
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(credential => {
        return this.updateUserData(credential.user);
      })
      .catch(error => this.handleError(error));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = this.afAuth;

    return fbAuth
      .sendPasswordResetEmail(email)
      .then(() =>  console.log('Password update email sent', 'info'))
      .catch(error => this.handleError(error));
  }


  userOffline(user) {
      const batch   = this.afs.firestore.batch();
      const db      = this.afs.firestore;
      const userKey = user.uid;
      const userRef        = db.collection('users').doc(userKey);
      // batch.update(userRef, 'inline', false);
      // batch.commit().then(function() {console.log('Batch Commited')});
       userRef.set({inline: false}, {merge: true});
       console.log(user.uid);
  }

  signOut(user) {
    var timer = Observable.interval(1000).startWith(0);
    return  timer.pipe(
     take(1),
      tap((e) => {
        // console.log(e);
       return this.afAuth.signOut().then(() => {
        this.router.navigate(['/login']);
        return  this.userOffline(user);
       });
      })
    ).subscribe();

    // this.logClass.getThelastTimestampInLog(this.afs,this.afAuth).pipe(
    //   take(1)
    // ).subscribe((val) => console.log(val));
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    console.log(error.message, 'error');
  }



  // get users
  getUsersList() {
    return this.afs.collection('users', ref => ref.where('role', '<', 'pending')
    .where('role','<','pending')).snapshotChanges().map(arr => {
      return arr.map(snap => Object.assign(
        snap.payload.doc.data(),
        { $key: snap.payload.doc.id }) );
    });
  }

  // change role
  changeRole(user, value) {
    const batch   = this.afs.firestore.batch();
    const db      = this.afs.firestore;
    const userKey = user.$key;
    console.log(value);
    const userRef        = db.collection('users').doc(userKey);
    batch.update(userRef, 'role', value);
    batch.commit().then(function() {console.log('Batch Commited')});
  }



  private updateUserData(user: User) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || 'nameless user',
      photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ',
      inline: true
    };
    return userRef.set(data, {merge: true});
  }



  googleRegister() {
    const provider = new firebase.default.auth.GoogleAuthProvider();
    console.log(provider);
    return this.AuthRegister(provider);
  }

  private AuthRegister(provider: any) {
    return this.afAuth.signInWithPopup(provider)
      .then(credential => {
        console.log('Welcome!!!', 'success');
        return this.updateUserDatas(credential.user);
      })
      .catch(error => this.handleError(error));
  }

  getUsersPendingList() {
    return this.afs.collection('users', ref => ref.where('role', '==', 'pending')).snapshotChanges().map(arr => {
      return arr.map(snap => Object.assign(
        snap.payload.doc.data(),
        { $key: snap.payload.doc.id }) );
    });
   }

  private updateUserDatas(user: User) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || 'nameless user',
      photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ',
      role: 'pending'
    };
    return userRef.set(data, {merge: true});
  }

  updateUserAfterMemberRegister(member: any, userId , userEmail,memberKey) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${userId}`
    );

    const data: any = {
      memberkey : memberKey,
      timestamp: Date.now(),
      firstname:       member?member.newMemberfirstname:null,
      lastname:        member?member.newMemberlastname:null,
      email:           userId?userEmail:null,
      phone:           member?member.newMemberphone:null,
      role: 'pending'
    };
    return userRef.set(data, { merge: true });
    // console.log(data);
  }

  deleteUser(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    return userRef.delete();
  }




}
