import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { EmailDialogComponent } from '../footer-nav/footer-nav.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(private router: Router,
              public dialog: MatDialog,
              public dialog2: MatDialog,
              ) {
              }

  ngOnInit() {
  }

  gotoUserProfile() {}


  openSenderMail(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40%';
    dialogConfig.backdropClass = 'footerDialog';
    dialogConfig.autoFocus = true;
    this.dialog2.open(EmailDialogComponent, dialogConfig);
  }

}

